/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    strong: "strong",
    br: "br",
    h3: "h3",
    ol: "ol",
    li: "li",
    a: "a",
    h4: "h4",
    pre: "pre",
    code: "code",
    ul: "ul",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, ResponseBody} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(_components.p, null, "Use our 3-D Secure feature to verify a cardholder’s identity during an e-Commerce transaction."), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " Sign up for 3-D Secure.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " Convert the cardholder’s payment details into a single-use token.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 3."), " Send a merchant plug-in (MPI) request.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 4."), " Include the MPI reference in a payment request."), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate a Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1. Sign up for 3-D Secure"), "\n", React.createElement(_components.p, null, "To sign up for 3-D Secure, contact our Customer Support team at ", React.createElement(_components.a, {
    href: "mailto:cs@payroc.com"
  }, "cs@payroc.com"), "."), "\n", React.createElement(_components.p, null, "We use request forwarding to send you the results of the 3-D Secure check. When you sign up for 3-D Secure, provide a URL that we forward the requests to."), "\n", React.createElement(_components.h2, null, "Step 2. Convert the cardholder’s payment details into a single-use token"), "\n", React.createElement(_components.p, null, "Before you can send a request to our MPI service, you need to convert the cardholder’s payment details into a single-use token."), "\n", React.createElement(_components.p, null, "To create a single-use token, you can use ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/guides/integrate/hosted-fields"
  }, "Hosted Fields"), " or you can use our tokenization feature in our ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api"
  }, "API"), "."), "\n", React.createElement(_components.h2, null, "Step 3. Send an MPI request"), "\n", React.createElement(_components.p, null, "Send the single-use token to our MPI service with information about the transaction in the query parameters."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://payments.uat.payroc.com/merchant/mpi"
  }, "https://payments.uat.payroc.com/merchant/mpi"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://payments.payroc.com/merchant/mpi"
  }, "https://payments.payroc.com/merchant/mpi")), "\n", React.createElement(_components.h3, null, "Query parameters"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Parameter"), React.createElement(_components.th, {
    align: "left"
  }, "Type"), React.createElement(_components.th, {
    align: "left"
  }, "Required?"), React.createElement(_components.th, {
    align: "left"
  }, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "processingTerminalId"), React.createElement(_components.td, {
    align: "left"
  }, "string"), React.createElement(_components.td, {
    align: "left"
  }, "Yes"), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that we assigned to the terminal.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "singleUseToken"), React.createElement(_components.td, {
    align: "left"
  }, "string"), React.createElement(_components.td, {
    align: "left"
  }, "Yes"), React.createElement(_components.td, {
    align: "left"
  }, "Unique token that the gateway assigned to the payment details.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "email"), React.createElement(_components.td, {
    align: "left"
  }, "string"), React.createElement(_components.td, {
    align: "left"
  }, "Yes"), React.createElement(_components.td, {
    align: "left"
  }, "Cardholder’s email address.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "amount"), React.createElement(_components.td, {
    align: "left"
  }, "number <double>"), React.createElement(_components.td, {
    align: "left"
  }, "Yes"), React.createElement(_components.td, {
    align: "left"
  }, "Total amount of the transaction, which includes surcharges. The value is in the currency’s lowest denomination, for example, cents.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "currency"), React.createElement(_components.td, {
    align: "left"
  }, "string"), React.createElement(_components.td, {
    align: "left"
  }, "Yes"), React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "https://www.iso.org/iso-4217-currency-codes.html"
  }, "ISO-4217"), " currency code of the transaction.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "orderId"), React.createElement(_components.td, {
    align: "left"
  }, "string"), React.createElement(_components.td, {
    align: "left"
  }, "Yes"), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that the merchant assigns to the order.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "cardholderChallenge"), React.createElement(_components.td, {
    align: "left"
  }, "string"), React.createElement(_components.td, {
    align: "left"
  }, "No"), React.createElement(_components.td, {
    align: "left"
  }, "Indicates if the merchant wants the issuing bank to challenge the cardholder. Send one of the following values:  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "REQUIRED"), " - Merchant wants the issuing bank to challenge the cardholder.  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "OPTIONAL"), " - Issuing bank decides whether to challenge the cardholder.")))), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "https://payments.payroc.com/merchant/mpi?processingTerminalId=4479001&amount=100&currency=EUR&orderId=25&email=joe%40adomain.com&singleUseToken=1a8731f50b02e287ac0529fbce352317c089d4adc1178c1867d65114078791d3c3e13962cbab6b574769dfe9ad5397a5aa67a529ceb0b7be17751f076bbe0e4d \n"))), "\n", React.createElement(_components.h3, null, "Response fields"), "\n", React.createElement(_components.p, null, "If your request is successful, we send a GET request to your MPI receipt URL with the results of the 3-D Secure check and the MPI reference. The response fields are in the query parameters of the GET request."), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Field"), React.createElement(_components.th, {
    align: "left"
  }, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "result"), React.createElement(_components.td, {
    align: "left"
  }, "Indicates the result of the 3-D Secure check. We return one of the following values:   ", React.createElement("br"), "• ", React.createElement(_components.code, null, "A"), " - The issuing bank approved the transaction.   ", React.createElement("br"), "• ", React.createElement(_components.code, null, "D"), " - The issuing bank declined the transaction.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "mpiReference"), React.createElement(_components.td, {
    align: "left"
  }, "MPI reference of the 3-D Secure check.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "orderId"), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that the merchant assigned to the order.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "status"), React.createElement(_components.td, {
    align: "left"
  }, "Status of the 3-D Secure check. We return one of the following values:   ", React.createElement("br"), "• ", React.createElement(_components.code, null, "A"), " - Issuing bank attempted to authenticate the cardholder’s identity.  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "N"), " - Issuing bank didn’t attempt to authenticate the cardholder’s identity.  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "U"), " - Issuing bank was unable to authenticate the cardholder’s identity.  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "Y"), " - Issuing bank authenticated the cardholder’s identity.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, "eci"), React.createElement(_components.td, {
    align: "left"
  }, "Response code from 3-D Secure. We return one of the following values:  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "05"), " - Issuing bank used 3-D Secure to authenticate the cardholder.  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "06"), " - Issuing bank or cardholder is not enrolled for 3D Secure.  ", React.createElement("br"), "• ", React.createElement(_components.code, null, "07"), " - 3-D Secure check failed.")))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "https://{MPI_RECEIPT_URL}?result=A&status=A&eci=06&mpiReference=d01656cf0ec3e62e3754&orderId=25\n"))), "\n", React.createElement(_components.h2, null, "Step 4. Run a sale"), "\n", React.createElement(_components.p, null, "To run a sale, send a POST request to our Payments endpoint with the single-use token that you generated in Step 2."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments"
  }, "https://api.uat.payroc.com/v1/payments"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/payments"
  }, "https://api.payroc.com/v1/payments")), "\n", React.createElement(_components.p, null, "In your request, send the following parameters in the threeDSecure object:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "serviceProvider"), " – Provide a value of ", React.createElement(_components.code, null, "gateway"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "mpiReference"), " – Provide the MPI reference that we sent your MPI receipt URL in Step 3."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "payment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, we create the payment and return a response. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "payment"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
